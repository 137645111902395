import { Injectable } from '@angular/core';
import { environment as environmentPromise } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private ENV: any;

  constructor() {}

  get env() {
    return this.ENV;
  }

  set env(env) {
    this.ENV = env;
  }

}
