import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SpinNavigationService } from '@spintec/components/navigation/navigation.service';
import { SpinSidebarService } from '@spintec/components/sidebar/sidebar.service';
import { SpinConfigService } from '@spintec/services/config.service';
import { SpinSplashScreenService } from '@spintec/services/splash-screen.service';
import { SpinTranslationLoaderService } from '@spintec/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { navigation } from 'app/navigation/navigation';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './service/authentication/authentication.service';
import { GeralService } from './service/geral/geral.service';
import { MessagingService } from './service/messaging/messaging.service';
import { OperadoraService } from './service/operadora/operadora.service';
import { UserService } from './service/user/user.service';



@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    spinConfig: any;
    navigation: any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        public geralService: GeralService,
        private _userService: UserService,
        private _keycloak: KeycloakService,
        private _spinConfigService: SpinConfigService,
        private _spinNavigationService: SpinNavigationService,
        private _spinSidebarService: SpinSidebarService,
        private _spinSplashScreenService: SpinSplashScreenService,
        private _spinTranslationLoaderService: SpinTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private messagingService: MessagingService,
        private _operadoraService: OperadoraService,
        private _authService: AuthenticationService,
        private _router: Router
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._spinNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._spinNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._spinTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._operadoraService.get(this._authService.getLoggedUserCpfCnpj()).subscribe((res: any) => {
            if (!res) {
                this._router.navigate([`/operadora/cadastro/`]);
            }
        });

        // Subscribe to config changes
        this._spinConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.spinConfig = config;

                // Boxed
                if (this.spinConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.spinConfig.colorTheme);
                this._keycloak.isLoggedIn().then(log => {
                    console.log(log);
                    this._userService.findUsuario()
                        .then(usu => {
                            this.geralService.templateVisible = log;
                            this.messagingService.requestPermission();
                            this.messagingService.receiveMessage();
                        }).catch(e => {
                            console.log(e);
                        });
                });
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._spinSidebarService.getSidebar(key).toggleOpen();
    }
}
