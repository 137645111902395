import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

 public isLoading: boolean;
 
  constructor(
    private spinner: NgxSpinnerService
  ) { }

  public loading() {
    this.isLoading = true;
    this.spinner.show();
  }

  public dismiss() {
      this.isLoading = false;
  }

}
