import {Component, OnInit, Inject, ViewEncapsulation, ViewChild, OnDestroy, Output, EventEmitter} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Solicitacao} from '../../../models/solicitacao';
import {SolicitacaoService} from '../../../service/solicitacao/solicitacao.service';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-dialog-cancelar-solicitacao',
    templateUrl: './dialog-cancelar-solicitacao.component.html',
    styleUrls: ['./dialog-cancelar-solicitacao.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogCancelarSolicitacaoComponent implements OnInit, OnDestroy {

    public solicitacao: any = {};
    public negar = false;
    observacao: any;
    private _unsubscribeAll = new Subject();

    constructor(
        public matDialogRef: MatDialogRef<DialogCancelarSolicitacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _matSnackBar: MatSnackBar,

    ) { }

    ngOnInit(): void {
        this.solicitacao = this.data.solicitacao;
        this.negar = this.data.negar;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    confirmar() {
        this.matDialogRef.close({ texto: this.observacao || '' , tipo: 'OPERADORA'});
    }
}
