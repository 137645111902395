import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SpinNavigationService } from '@spintec/components/navigation/navigation.service';

@Component({
    selector: 'spin-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _spinNavigationService: SpinNavigationService
    ) {
        this._unsubscribeAll = new Subject();
    }


    ngOnInit(): void {
        this.navigation = this.navigation || this._spinNavigationService.getCurrentNavigation();

        this._spinNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                this.navigation = this._spinNavigationService.getCurrentNavigation();
                this._changeDetectorRef.markForCheck();
            });

        merge(
            this._spinNavigationService.onNavigationItemAdded,
            this._spinNavigationService.onNavigationItemUpdated,
            this._spinNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                this._changeDetectorRef.markForCheck();
            });
    }
}
