import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PessoaService {

  private url = 'rs/v1/pessoas';

  constructor(private http: HttpClient) { }

  findByNomePessoaCpfLike(search, success) {

    const observe = 'response';
    let params =  new HttpParams();

    if (search) {
      params = params.append('search', search );
    }
    params = params.append('limit', '5' );

    this.http.get(this.url + '/clicker/autocomplete', {
      observe,
      params
    })
      .subscribe(res => {
        success(res.body);
      });
  }

  findFornecedores(search, success) {
    this.findFornecedoresBySearchAndDistance(search, null, null, null, success);
  }

  findFornecedoresBySearchAndDistance(search, longitude, latitude, distancia, success) {

    const observe = 'response';
    let params =  new HttpParams();

    if (search && search !== null && search.trim() !== '') {
      params = params.append('search', search );
    }
    if (longitude && longitude !== null) {
      params = params.append('longitude', longitude );
    }
    if (latitude && latitude !== null) {
      params = params.append('latitude', latitude );
    }
    if (distancia && distancia !== null) {
      params = params.append('distancia', distancia );
    }

    this.http.get(this.url + '/fornecedoresbysearch', {
      observe,
      params
    })
      .subscribe(res => {
        success(res.body);
      });
  }

  public findByCnpj(cnpj) {
    return this.http.get(this.url + '/' + cnpj);
  }

}
