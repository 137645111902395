import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { Provider } from 'app/models/provider';
import { ItemTabelaPrecoService } from 'app/service/itemtabelapreco/item-tabela-preco.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
    selector: 'app-dialog-clinica-procedimento-list',
    templateUrl: './dialog-clinica-procedimento-list.component.html',
    styleUrls: ['./dialog-clinica-procedimento-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogClinicaProcedimentoListComponent implements OnInit {

    public prestador: Provider = new Provider();

    public filter: string = '';
    public filterChanges: Subject<void> = new Subject();
    public totalElements: number = 0;
    public currentPage: number = 0;

    public temp = [];
    public rows = [];

    private pageSize: number = 5;

    columns: Array<TableColumn> = [
        { prop: 'codigoItem', name: 'Código', flexGrow: 1 },
        { prop: 'comercialItem', name: 'Procedimento', flexGrow: 2 },
        { prop: 'valorTotal', name: 'Valor', flexGrow: 1 }
    ];

    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    constructor(
        public matDialogRef: MatDialogRef<DialogClinicaProcedimentoListComponent>,
        private itemTabelaPrecoService: ItemTabelaPrecoService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.prestador = this.data;

        this.filterChanges.pipe(
            debounceTime(400)).
            subscribe(this.resetPagination.bind(this));

        this.findAllPagination();
    }

    updatePaginationInfo(event: any) {
        this.currentPage = event.offset;
        this.findAllPagination();
    }

    findAllPagination() {
        this.itemTabelaPrecoService.findAllPagination(
            this.prestador.cpfCnpj,
            this.currentPage,
            this.pageSize,
            this.filter)
            .subscribe((v: any) => {
                this.prestador.tabelaPreco = v.content;
                this.totalElements = v.totalElements;
            });
    }

    private resetPagination() {
        this.currentPage = 0;
        this.totalElements = 0;
        this.findAllPagination();
    }
}
