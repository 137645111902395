
import { KeycloakConfig, KeycloakService } from 'keycloak-angular';
import { environment as environmentPromise } from '../../environments/environment';
import { EnvironmentService } from '../service/environment/environment.service';

export function initializer(keycloak: KeycloakService,
    environmentService: EnvironmentService): () => Promise<any> {

    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const env = await environmentPromise;
                environmentService.env = env;
                let keycloakConfig: KeycloakConfig = {
                    url: env["url"],
                    realm: env["realm"],
                    clientId: env["clientId"],

                };
                await keycloak.init({
                    config: keycloakConfig,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: false,
                        flow: 'implicit'
                    },
                    bearerExcludedUrls: [],
                });
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}
