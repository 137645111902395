import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ItemTabelaPrecoService {

    private url = 'rs/v1/itemtabelapreco';

    constructor(private http: HttpClient) { }


    findAutoComplete(cpfCnpj, search, success) {
        const observe = 'response';

        this.http.get(this.url + '/autocomplete/' + cpfCnpj + '/' + search, {
            observe
        }).subscribe(res => {
            success(res.body);
        });
    }

    public findAllPagination(cnpj, pageNumber, pageSize, filter) {

        const observe = 'body';
        let params = new HttpParams();

        if (pageNumber) {
            params = params.append('page', pageNumber);
        }

        if (pageSize) {
            params = params.append('size', pageSize);
        }

        if (filter) {
            params = params.append('search', filter);
        }

        return this.http.get(this.url + '/pagination/' + cnpj, {
            observe,
            params
        });
    }
}
