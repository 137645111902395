import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeralService {

  private TEMPLATE_VISIBLE = false;

  constructor() { }

  get templateVisible() {
    return this.TEMPLATE_VISIBLE;
  }

  set templateVisible(visible) {
    this.TEMPLATE_VISIBLE = visible;
  }

  reload() {
    this.TEMPLATE_VISIBLE = false;
    setTimeout( () => {
      this.templateVisible = true;
    },500);

  }


}
