import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { Assinatura } from '../../models/assinatura';
import { Operadora } from 'app/models/cadastro';

@Injectable({
    providedIn: 'root'
})
export class OperadoraService {

    private url = 'rs/v1/operadora/';

    constructor(
        private http: HttpClient
    ) { }

    public save(operadora: Operadora) {
        return this.http.post(this.url + 'save', operadora);
    }

    public get(cnpj: string) {
        return this.http.get(this.url + cnpj);
    }
}
