import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import {EnvironmentService} from '../environment/environment.service';
@Injectable({
    providedIn: 'root'
})
export class UploadService {

    private FOLDER = 'files-';

    constructor(private environmentService: EnvironmentService) { }

    uploadFile(file, folder = this.FOLDER) {
        const contentType = file.type;
        const bucket = new S3(
            {
                accessKeyId: this.environmentService.env.s3bucketAccessKeyId,
                secretAccessKey: this.environmentService.env.s3bucketSecretAccessKey,
                region: this.environmentService.env.region
            }
        );
        const params = {
            Bucket: this.environmentService.env.s3BucketNameBucket,
            Key: folder + file.key,
            Body: file,
            ContentType: contentType
        };
        return bucket.upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            console.log('Successfully uploaded file.', data);
            return true;
        }).promise();

    }

    downloadFile(key, folder = this.FOLDER) {
        const bucket = new S3(
            {
                accessKeyId: this.environmentService.env.s3bucketAccessKeyId,
                secretAccessKey: this.environmentService.env.s3bucketSecretAccessKey,
                region: this.environmentService.env.region
            }
        );

        const params = {
            Bucket: this.environmentService.env.s3BucketNameBucket,
            Key: folder + key
        };
        console.log(key);
        bucket.getObject(params, (err:any, data:any) =>{
            if (err) {
                console.log(err);
                // shows AWS s3 error
            }else{
                console.log(data);
                console.log(key.split("-"));
                let url = window.URL.createObjectURL(new Blob([data.Body], { type: data.ContentType }));
                var downloader = document.createElement('a');
                downloader.setAttribute('href', url);
                downloader.setAttribute('download', key.replace(key.split('-')[0] + '-', ''));
                downloader.click();
            }
        }).on('httpDownloadProgress',(progress) => {
            // shows file download progress
            console.log(progress);
        });
    }

    findUrl(folder, key) {
        const bucket = new S3(
            {
                accessKeyId: this.environmentService.env.s3bucketAccessKeyId,
                secretAccessKey: this.environmentService.env.s3bucketSecretAccessKey,
                region: this.environmentService.env.region
            }
        );
        const url = bucket.getSignedUrl('getObject', {
            Bucket: this.environmentService.env.s3BucketNameBucket,
            Key: folder + key,
        });
/*        const url = bucket.getSignedUrlPromise('getObject', {
            Bucket: this.environmentService.env.s3BucketNameBucket,
            Key: folder + key,
        });*/
        return url;
    }
}
