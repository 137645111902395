import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinProgressBarService } from '@spintec/components/progress-bar/progress-bar.service';
import { EnvironmentService } from 'app/service/environment/environment.service';
import { UserService } from 'app/service/user/user.service';
import { KeycloakService } from 'keycloak-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from './../service/loading/loading.service';



@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
        private _progressBar: SpinProgressBarService,
        private environmentService: EnvironmentService,
        private keycloakService: KeycloakService,
        private matSnackBar: MatSnackBar,
        public userService: UserService,
        public loading: LoadingService,) { }

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        request = request.clone();

        if (!request.url.endsWith('autocomplete')
            && !request.url.endsWith('fornecedoresbysearch')
            && !request.url.endsWith('especialidadedisponivel/search')) {
            this._progressBar.show();
            this.loading.loading();
        }

        const env: any = this.environmentService.env;
        let baseurl = env.baseurl;
        if (baseurl && baseurl !== '') {
            baseurl += '/';
        }

        const operadoraCpfCnpj = this.userService.usuario ? this.userService.usuario.cpfCnpj : '';

        const req = request.clone(
            {
                headers: request.headers.set('operadora-cpfcnpj', request.url.startsWith('rs/v1/pessoas/userfornecedores')
                    ? ''
                    : operadoraCpfCnpj).set('Content-Type', 'application/json')
                , url: `${baseurl}${request.url}`
            });

        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this._progressBar.hide();
                    this.loading.dismiss();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.keycloakService.logout();
                } else if (error.status === 403) {
                    alert("Você logou com um usuário de prestador e será deslogado!");
                    this.keycloakService.logout();
                } else {
                    const err = error.error;
                    if (error.status === 400 && err) {
                        this._progressBar.hide();
                        this.loading.dismiss();

                        err.errors.map(erro => {
                            console.log(erro);
                            this.matSnackBar.open(erro.message, '', { duration: 5000 });
                        });
                        return throwError(error);
                    } if (error.status === 305 && err) {
                        this._progressBar.hide();
                        this.loading.dismiss();

                        err.errors.map(erro => {
                            console.log(erro);
                            this.matSnackBar.open(erro.message, '', { duration: 5000 });
                        });
                        // this.userService.showTermo();
                        return throwError(error);
                    } else {
                        this._progressBar.hide();
                        this.loading.dismiss();

                        this.matSnackBar.open('Erro de comunicação, tente novamente mais tarde!', '', { duration: 5000 });
                        return throwError(error);
                    }
                }
            })
        );

    }

}
