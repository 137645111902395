import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private defaultUser = '65297048000159';

    private url = 'rs/v1/papeis';
    private resultCache = {};
    private checkingOperacao = false;
    private ehOperacao = false;

    constructor(
        private http: HttpClient,
        private keycloakService: KeycloakService) { }

    getCheckIsOperacao() {
        const cpfCnpj = this.getLoggedUserCpfCnpj();
        let _checkingOperacao = sessionStorage.getItem(cpfCnpj + '-checkingOperacao');
        if(_checkingOperacao == null){
            _checkingOperacao = "false";
        }
        return (_checkingOperacao.toLowerCase() == 'true');
    }

    getEhOperacao() {
        const cpfCnpj = this.getLoggedUserCpfCnpj();
        let _ehOperacao = sessionStorage.getItem(cpfCnpj + '-ehOperacao');
        
        if(_ehOperacao == null){
            _ehOperacao = "false";
        }

        return (_ehOperacao.toLowerCase() == 'true');

    }


    getLoggedUserCpfCnpj(): string {
        try {
            return this.keycloakService.getUsername();
        } catch (e) {
            console.log(`Error getting logged user: ${e}. Returning default user`)
            return this.defaultUser;
        }
    }

    checkIsOperacao() {
        const cpfCnpj = this.getLoggedUserCpfCnpj();

        if (!this.getEhOperacao() && !this.getCheckIsOperacao()) {
            this.checkingOperacao = true;
            sessionStorage.setItem(cpfCnpj + '-checkingOperacao', this.checkingOperacao.toString());

            this.http.get(`${this.url}/isOperacao/${cpfCnpj}`).subscribe(
                result => {                    
                    sessionStorage.setItem(cpfCnpj + '-ehOperacao', result.toString());                    
                }
            );
        }
    }

    isOperacao() {

        if (this.getCheckIsOperacao()) {
            return this.getEhOperacao();
        }

        this.checkIsOperacao();
        
        return false;
    }

}
