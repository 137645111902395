import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCancelarSolicitacaoComponent } from './dialog-cancelar-solicitacao.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SpinSharedModule } from '@spintec/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    declarations: [
        DialogCancelarSolicitacaoComponent
    ],
    imports: [
        CommonModule,
        SpinSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        NgxDatatableModule,
    ]
})
export class DialogCancelarSolicitacaoModule { }
