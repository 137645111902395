import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Provider } from 'app/models/provider';

@Component({
    selector: 'app-dialog-clinica-especialidade-list',
    templateUrl: './dialog-clinica-especialidade-list.component.html',
    styleUrls: ['./dialog-clinica-especialidade-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogClinicaEspecialidadeListComponent implements OnInit {

    public prestador: Provider;

    constructor(
        public matDialogRef: MatDialogRef<DialogClinicaEspecialidadeListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.prestador = this.data;
    }

}
