import { ElementRef, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    getElementValue(element: ElementRef): string {
        return element.nativeElement.value;
    }

    updateInputValue(element: ElementRef, value: string) {
        element.nativeElement.value = value.replace(/\D/g, '');
    }

    removeNonDigits(value: string): string {
        return value.replace(/\D/g, '');
    }
}