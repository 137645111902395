import { ContaFornecedor } from './contaFornecedor';
import { CorpoClinico } from './corpoClinico';
import { Especialidade } from './especialidade';
import { PessoaEndereco } from './pessoaEndereco';
import { PessoaHorario } from './pessoaHorario';
import { TabelaPrecoClic } from './tabelaPrecoClic';
import { VerificacaoConta } from './verificacaoConta';

export class Provider {
    cdPrestador: number;
    contaFornecedor: ContaFornecedor;
    contato: string;
    corpoClinico: CorpoClinico[];
    cpfCnpj: string;
    dtCreated: string;
    dtUpdated: string;
    email: string;
    enderecos: PessoaEndereco[];
    especialidades: Especialidade[];
    horarios: PessoaHorario[];
    dsHorarios: any[];
    dsImage: any;
    iNome: string;
    iNomeFantasia: string;
    id: string;
    idVersion: number;
    ieAtivo: boolean;
    nome: string;
    nomeFantasia: string;
    observacao: string;
    pj: boolean;
    planos: string[];
    seq: number;
    site: string;
    telefone: string;
    telefones: string[];
    tipo: 'FORNECEDOR' | 'CLICKER' | 'CLICDIGITAL'[];
    subtipoEstabelecimento: string;
    tabelaPreco: TabelaPrecoClic[];
    tipoEstabelecimento: string;
    tipoPrestador: string;
    urlImage: string;
    userCreated: string;
    userUpdated: string;
    verificacaoConta: VerificacaoConta;
    verificacaoValida: boolean;
    _itemTabelaPrecoService: any;
}
