import { Directive, ElementRef, HostListener, OnChanges } from "@angular/core";
import { SharedService } from "../services/shared.service";

@Directive({
    selector: '[numberInput]'
})
export class NumberInputDirective implements OnChanges {

    constructor(
        private sharedService: SharedService,
        private el: ElementRef) {
    }

    @HostListener('input') ngOnChanges() {
        const currVal = this.sharedService.getElementValue(this.el);
        const newVal = this.sharedService.removeNonDigits(currVal);
        this.sharedService.updateInputValue(this.el, newVal);
    }
}
