export * from './dialog-clinica-especialidade-list/dialog-clinica-especialidade-list.component';
export * from './dialog-clinica-especialidade-list/dialog-clinica-especialidade-list.module';
export * from './dialog-clinica-procedimento-list/dialog-clinica-procedimento-list.module';
export * from './dialog-clinica-procedimento-list/dialog-clinica-procedimento-list.component';
export * from './dialog-importar-inidicacao-clinica/dialog-importar-inidicacao-clinica.component';
export * from './dialog-importar-inidicacao-clinica/dialog-importar-inidicacao-clinica.module';
export * from './dialog-termo/dialog-termo.module';
export * from './dialog-termo/dialog-termo.component';
export * from './dialog-cancelar-solicitacao/dialog-cancelar-solicitacao.component';
export * from './dialog-cancelar-solicitacao/dialog-cancelar-solicitacao.module';
