// google maps
import { AgmCoreModule } from '@agm/core';
// Locale
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import pt from '@angular/common/locales/pt';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
//FCM
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SpinProgressBarModule, SpinSidebarModule } from '@spintec/components';
import { SpinSharedModule } from '@spintec/shared.module';
import { SpintecModule } from '@spintec/spintec.module';
import { AgmDirectionModule } from 'agm-direction';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import 'hammerjs';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuard } from './guards/auth.guard';
// interceptor
import { HttpConfigInterceptor } from './interceptors/http-config.interceptor';
// import { AssinaturaModule } from './main/assinatura/assinatura.module';
import { ToastrModule } from 'ngx-toastr';
import { DialogTermoModule } from './main/dialogs';
import { EnvironmentService } from './service/environment/environment.service';
import { MessagingService } from './service/messaging/messaging.service';
import { SharedModule } from './shared/shared.module';
import { spinConfig } from './spin-config';
// keycloak
import { initializer } from './utils/app-init';
//Exportar
import { ExportService } from './service/export/export.service'

registerLocaleData(pt);


const appRoutes: Routes = [
    {
        path: 'atendimento',
        loadChildren: () => import('./main/atendimento/atendimento.module').then(m => m.AtendimentoModule)
    },
    {
        path: 'solicitacao',
        loadChildren: () => import('./main/solicitacao/solicitacao.module').then(m => m.SolicitacaoModule)
    },
    // {
    //     path: 'assinatura',
    //     loadChildren: () => import('./main/assinatura/assinatura.module').then(m => m.AssinaturaModule)
    // },
    {
        path: 'operadora',
        loadChildren: () => import('./main/operadora/operadora.module').then(m => m.OperadoraModule)
    },
    {
        path: '**',
        redirectTo: '/atendimento/acompanhamento/solicitacoes',
    },
];

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        TranslateModule.forRoot(),
        // Material moment
        MatMomentDateModule,
        DialogTermoModule,
        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        // Key Cloak
        KeycloakAngularModule,
        SpintecModule.forRoot(spinConfig),
        SpinProgressBarModule,
        SpinSharedModule,
        SpinSidebarModule,
        // AssinaturaModule,

        ToastrModule.forRoot(),

        NgxSpinnerModule,

        // Google Maps
        AgmDirectionModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyADdo19uEEtN01ns3_hJ8bVMaociKXlD6Q',
            libraries: ['places', 'geometry']
        }),
        AngularFireModule.initializeApp({
            apiKey: "AIzaSyDolcP7sZmHTteAePSrDVECoE9wYBEgHhs",
            authDomain: "redeflix-fd80d.firebaseapp.com",
            databaseURL: "https://redeflix-fd80d.firebaseio.com",
            projectId: "redeflix-fd80d",
            storageBucket: "redeflix-fd80d.appspot.com",
            messagingSenderId: "87831460251",
            appId: "1:87831460251:web:301e8e42ebbea3c9d2093e"
        }),
        AngularFireMessagingModule,
        AngularFireAuthModule,
        // App modules
        LayoutModule,
        SharedModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        MessagingService,
        AuthGuard,
        ExportService,
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, EnvironmentService]

        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
