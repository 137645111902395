import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadService } from '../../../service/upload/upload.service';

@Component({
    selector: 'app-dialog-importar-inidicacao-clinica',
    templateUrl: './dialog-importar-inidicacao-clinica.component.html',
    styleUrls: ['./dialog-importar-inidicacao-clinica.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogImportarInidicacaoClinicaComponent implements OnInit, OnDestroy {

    public fileSelected
    public fileList: any[] = [];
    public files$: BehaviorSubject<any>;

    public arquivoCtrl = new FormControl(null, Validators.required);
    public observacaoCtrl = new FormControl(null);

    private _unsubscribeAll = new Subject();

    constructor(
        public matDialogRef: MatDialogRef<DialogImportarInidicacaoClinicaComponent>,
        private uploadService: UploadService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.files$ = new BehaviorSubject(this.data || []);
    }

    ngOnInit(): void {
        this.files$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((files) => {
                this.fileList = files;
            });

        this.arquivoCtrl.disable();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    public selecionarArquivo(event): void {

        this.fileSelected = null;
        const selectedFiles = event.target.files;

        if (!selectedFiles.item(0)) {
            return;
        }

        const file = selectedFiles.item(0);
        this.fileSelected = file;
        this.fileSelected.key = new Date().getTime() + '-' + file.name;
        this.uploadService.uploadFile(this.fileSelected);
        this.adicionaArquivo();
    }


    public adicionaArquivo(): void {

        const fileName = this.fileSelected.name;
        const observacao = '';

        if (!fileName) { return; }

        const fileObject = {
            file: this.fileSelected,
            fileName: fileName,
            observacao: observacao,
        };

        this.fileList.push(fileObject);
        this.files$.next([...this.fileList]);
        this.fileSelected = null;
    }

    public removeArquivo(data): void {
        const index = this.fileList.findIndex((v) => v.fileName === data.fileName);
        this.fileList.splice(index, 1);
        this.files$.next([...this.fileList]);
    }

    public download(anexo) {
        this.uploadService.downloadFile(anexo.key);
    }
}
