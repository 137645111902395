import { SpinNavigation } from '@spintec/types';

export const navigation: SpinNavigation[] = [
    // {
    //     id       : 'assinatura',
    //     title    : 'Assinatura',
    //     type     : 'group',
    //     children : [
    //         {
    //             id       : 'assinatura',
    //             title    : 'Assinatura',
    //             type     : 'item',
    //             icon     : 'assignment',
    //             url      : '/assinatura',
    //             enabled : false,
    //         }
    //     ]
    // },
    {
        id       : 'auditoria',
        title    : 'Auditoria',
        type     : 'group',
        children : [
            {
                id       : 'nova-solicitacao',
                title    : 'Nova Solicitação',
                type     : 'item',
                icon     : 'add',
                url      : 'solicitacao/nova',
            },
            {
                id       : 'acompanhamento-solicitacao',
                title    : 'Solicitações',
                type     : 'item',
                icon     : 'assignment',
                url      : '/atendimento/acompanhamento/solicitacoes',
            },
            {
                id       : 'atendimentos',
                title    : 'Atendimentos',
                type     : 'item',
                icon     : 'playlist_add_check',
                url      : '/atendimento/acompanhamento/atendimentos',
            },
            {
                id: 'download-forms',
                title: 'Área de Download - Formulários',
                type: 'collapsable',
                icon: 'cloud_download',
                children: [
                    {
                        id: 'auditoria-contas-hospitalares-download',
                        title: 'Auditoria Contas Hospitalares',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/Auditoria-Contas-Hospitalares-SAUDE-FLIX.docx',
                    },
                    {
                        id: 'autorizacao-auditoria-download',
                        title: 'Aut. para Auditoria em Saúde',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/Autorizacao-para-a-realizacao-da-Auditoria-em-Saude-SAUDE-FLIX.docx',
                    },
                    {
                        id: 'termo-aceite-download',
                        title: 'Termo de aceite da operadora',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/Termo-de-aceite-da-operadora-SAUDE-FLIX.docx',
                    },
                    {
                        id: 'termo-resp-download',
                        title: 'Termo de Responsabilidade',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/Termo-de-Responsabilidade-SAUDE-FLIX.docx',
                    },
                    {
                        id: 'visita-hosp-download',
                        title: 'Visita Hospitalar',
                        type: 'item',
                        icon: 'link',
                        externalUrl: true,
                        url: 'https://instaladores.qualirede.com.br/siteqr/Visita-Hospitalar-SAUDE-FLIX.docx',
                    }
                ]
            }
        ]
    }
];
