import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuariofcmService {

  private url = 'rs/v1/usuariosfcm';

  constructor(private http: HttpClient) { }

  save(usuariofcm, success) {
    const observe = 'response';
    this.http.post(this.url, usuariofcm, {observe})
      .subscribe(response => {
        success(response.body);
      });
  }
}
