import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogTermoComponent } from 'app/main/dialogs';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public usuario;
    public usuario$: BehaviorSubject<any>;
    private url = 'rs/v1/users';

    constructor(
        private authService: AuthenticationService,
        private matDialog: MatDialog,
        private http: HttpClient) {
        this.usuario$ = new BehaviorSubject({});
    }

    findUserData() {
        console.log('Find user data');
        const observe = 'body';
        return this.http.get(this.url + '/userdata', {
            observe
        });
    }

    /*findUsuario() {
        return new Promise((resolve, reject) => {
            const observe = 'body';
            this.http.get(this.url, { observe })
                .subscribe((userData) => {
                    this.usuario = userData;
                    this.usuario$.next(this.usuario);
                    resolve(userData);
                },
                    err => {
                        console.error(err);
                        reject(err);
                    });
        });
    }*/

    findUsuario() {
        return this.http.get(`${this.url}/${this.authService.getLoggedUserCpfCnpj()}`).toPromise();
    }

    findTermoAceite() {
        const observe = 'body';
        return this.http.get(this.url + '/termo', {
            observe
        });
    }

    aceitarTermo() {
        const observe = 'response';
        return this.http.post(this.url + '/aceitartermo', null, { observe });
    }

    showTermo(): void {
        const observe = 'body';
        this.http.get(this.url + '/termo', {
            observe
        }).subscribe((termoObject) => {
            this.matDialog.open(DialogTermoComponent, {
                panelClass: 'app-dialog-termo',
                autoFocus: false,
                data: termoObject
            });
        });
    }

}
