import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import {UsuariofcmService} from '../usuariofcm/usuariofcm.service';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private usuariofcmService: UsuariofcmService) {

    this.angularFireMessaging.messaging.subscribe(
      ( _messaging ) => {
        console.log(_messaging);
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  updateToken(token) {
    this.usuariofcmService.save({_FCMtokenId: token }, data => {
      console.log(data);
    });
  }

  requestPermission() {
    // this.angularFireMessaging.requestToken.subscribe(
    //   (token) => {
    //     console.log(token);
    //     this.updateToken(token);
    //   },
    //   (err) => {
    //     console.error('Unable to get permission to notify.', err);
    //   }
    // );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log( "payload");
        console.log( payload);
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      });
  }
}
